<template lang="html">
  <aside class="sidebar">
    <div class="menu-group">
      <!-- 홈 -->
      <div class="menu-item">
        <router-link class="menu-link" :class="$route.meta.group === 'homePage' ? 'active' : ''" to="/main/home">
          <i class="ic-left-menu-home-01"></i><span class="menu-txt">홈</span>
        </router-link>
      </div>
      <!--// 홈 -->

      <!-- 대시보드 -->
      <div class="menu-item">
        <router-link class="menu-link" :class="$route.meta.group === 'dashboardPage' ? 'active' : ''" to="/main/dashboard">
          <i class="ic-left-menu-dash-01"></i><span class="menu-txt">대시보드</span>
        </router-link>
      </div>
      <!--// 대시보드 -->

      <!-- 단말기 -->
      <div>
        <div class="state-group">
          <div class="state-head">
            <p class="title">단말기</p>
            <!-- <div class="filter">
              <button type="button" class="element-btn bg-navy size-xs round">
                <i class="icon-img ic-menu-filter"></i>
                필터
                <i class="icon-img ic-filter-delete"></i>
              </button>
            </div> -->
          </div>


          <ul class="state-list" v-for="(sItem, idx) in siteList" :key="idx">
            <li>
              <button type="button" class="state-link has-menu active">
                <!-- <span class="link-txt">{{sItem.companyName}}</span> -->
                <span class="link-txt">{{sItem.siteName}}</span>
                <i class="ic-menu-more"></i>
              </button>

              <!-- <div v-for="(mItem, jdx) in sItem.mapInfo" :key="jdx">
                {{mItem.mapName}}
              </div> -->
              
              <ul v-for="(mItem, jdx) in sItem.mapInfo" :key="jdx">
                <li>
                  <button type="button" class="state-link has-menu active">
                    <span class="link-txt">test</span>
                    <i class="ic-menu-more"></i>
                  </button>

                  <ul>
                    <li>
                      <a href="" class="state-link">
                        <i class="ic-state1"></i>
                        <span class="link-txt">A 화장실</span>
                        <i class="badge-new">5</i>
                      </a>
                    </li>
                    <li>
                      <a href="" class="state-link">
                        <i class="ic-state2"></i>
                        <span class="link-txt">B 화장실</span>
                      </a>
                    </li>
                    <li>
                      <a href="" class="state-link">
                        <i class="ic-state1"></i>
                        <span class="link-txt">C 화장실</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          
          
          
        </div>
      </div>
      <!--// 단말기 -->

      <!-- 설정 -->
      <div class="menu-item">
        <button type="button" class="menu-link has-menu">
          <i class="ic-left-menu-setting-01"></i>
          <span class="menu-txt">설정</span>
          <i class="ic-menu-more"></i>
        </button>
        <div class="submenu-group">
          <div class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingCompany' ? 'active' : ''" to="/main/setting/companylist">현장관리</router-link></div>
          <div class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingDevice' ? 'active' : ''" to="/main/setting/deviceadmin">단말기 관리</router-link></div>
          <div v-if="getUserRole==='admin'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingMap' ? 'active' : ''" to="/main/setting/mapadmin">맵 관리</router-link></div>
          <div v-if="getUserRole==='admin'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingManager' ? 'active' : ''" to="/main/setting/managerlist">계정 관리</router-link></div>
          <div v-if="getUserRole==='admin'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingSite' ? 'active' : ''" to="/main/setting/siteadmin">사이트 설정</router-link></div>
        </div>
      </div>
      <!--// 설정 -->
    </div>

    <!-- Log Out -->
    <div class="logout">
      <button type="button" class="btn-logout" @click="logoutAction()">
        <span class="btn-txt">Log Out</span>
        <i class="ic-logout-arrow"></i>
      </button>
    </div>
    <!--// Log Out -->
  </aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { requestApi } from "@/utils/api"

export default {
  async created () {
    await this.getSiteList()
    await this.getMapList()

    for(let i=0; i<this.siteList.length; i++) {
      let mapInfo = this.mapList.filter(x => x.siteGuid === this.siteList[i].siteGuid)
      this.siteList[i].mapInfo = mapInfo
    }

    console.log(this.siteList)
    
    setTimeout(() => {
      this.menuToggle();
    }, 1000)
    
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getUserRole","getCompanyGuid"])
  },
  data () {
    return {
      isShowAlarm: false,
      siteList: [],
      mapList: [],
    }
  },
  methods: {
    ...mapActions(["userLogoutAction"]),
    logoutAction () {
      this.userLogoutAction();
      this.$router.push({name:"IndexPage"})
    },
    menuToggle() {
      console.log("make menu")
      const hasMenu = document.querySelectorAll('.has-menu');
      console.log(hasMenu)
      for (let i = 0; i < hasMenu.length; i++) {
        hasMenu[i].addEventListener('click', function() {
          this.classList.toggle('active');
        })
      }
    },

    async getSiteList () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteList = result.msg.retData;
      }
    },
    async getMapList () {
      let reqObj = {}
      reqObj.target = `/map/listall/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapList = result.msg.retData
      }
    }

  }
  
}
</script>

<style lang="">
  
</style>